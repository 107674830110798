import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import BlockContent from '../../BlockContent'
import {
  Wrapper,
  ContentWrapper,
  Title,
  Description,
  Form,
  Label,
  Input,
  Textarea,
  Button
} from './elements'

export default connect(
  {
    contactForm: state`contactForm`,
    submitMessage: state`submitMessage`,
    fieldChanged: signal`fieldChanged`,
    contactFormSubmitted: signal`contactFormSubmitted`
  },
  function Contact ({ contactForm, submitMessage, contactFormSubmitted, fieldChanged }) {
    const formIsValid = Object.keys(contactForm).filter((field) => {
      return contactForm[field].error
    }).length === 0

    return (
      <Wrapper>
        <ContentWrapper>
          <Title>Get in touch</Title>
          <Description>Get in touch with us through social media or by using the form below.</Description>
          <Form onSubmit={() => { if (formIsValid) contactFormSubmitted() } }>
            <Label>Your name</Label>
            <Input
              onChange={(e) => fieldChanged({ field: 'name', value: e.target.value })}
              value={contactForm.name.value}
              error={contactForm.name.error}
              required
              type="text"
              minlength={1}
            />
            <Label>Your email</Label>
            <Input
              onChange={(e) => fieldChanged({ field: 'email', value: e.target.value })}
              value={contactForm.email.value}
              error={contactForm.email.error}
              required
              type="email"
            />
            <Label>Message</Label>
            <Textarea
              onChange={(e) => fieldChanged({ field: 'message', value: e.target.value })}
              value={contactForm.message.value}
              error={contactForm.message.error}
              required
              type="text"
              minlength={5}
            />
            <Button disabled={!formIsValid} error={!formIsValid} type="button" onClick={() => { if (formIsValid)contactFormSubmitted() }}>Send message</Button>
          </Form>
          <br/>
          {submitMessage}
        </ContentWrapper>
      </Wrapper>
    )
  }
)
