import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 312px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(5.7deg, #ef6f2e 20%, #481f87 93%);
  padding-bottom: 64px;

  @media(max-width: 767px) {
    padding-top: 100px;
  }
`

export const ContentWrapper = styled.div`
  width: 1024px;
  padding-bottom: 64px;

  @media(max-width: 1045px) {
    width: 100%;
    padding: 0 24px;
  }

  @media(max-width: 767px) {
    width: 100%;
    padding: 0 8px 64px 8px;
  }
`

export const Title = styled.h1`
  font-size: 40px;
  line-height: 38px;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'TradeGothic18Obl';
  margin-top: 4px;
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
`
export const Description = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 24px;
`

export const Form = styled.form`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  width: 390px;

  @media(max-width: 767px) {
    width: 100%;
  }
`

export const Label = styled.label`
  display: block;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
`

export const Input = styled.input`
  border: 2px solid ${props => props.error ? 'red' : '#462087'};
  background-color: #462087;
  padding: 12px 16px;
  color: #fff;
  font-size: 16px;
  width: 100%;
  margin-bottom: 24px;
`

export const Textarea = styled.textarea`
  border: 2px solid ${props => props.error ? 'red' : '#462087'};
  background-color: #462087;
  padding: 12px 16px;
  color: #fff;
  font-size: 16px;
  width: 100%;
  height: 200px;
  margin-bottom: 24px;
`

export const Button = styled.button`
  background-color: #462087;
  border: 2px solid transparent;
  color: #fff;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  cursor: ${props => props.error ? 'default' : 'pointer'};
  opacity: ${props => props.error ? 0.7 : 1};

  &:hover {
    background-color: ${props => props.error ? '#462087' : '#30165d'};
  }
`
