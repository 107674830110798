import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import { client as sanityClient } from '../../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import {
  Wrapper,
  BackgroundImage,
  PurpleShape,
  OrangeShapeLeft,
  OrangeShapeRight,
  CenterWrapper,
  Title,
  DisplayDate,
  Slashes,
  TitleDots,
  Preamble,
  ArticlePreviewWrapper,
  PaginationWrapper,
  Pagination,
  Link
} from './elements'

export default connect(
  {
    latestNews: state`latestNews`,
    currentArticleIndex: state`currentArticleIndex`,
    articlePaginationClicked: signal`articlePaginationClicked`
  },
  function NewsSection ({
    isSmall,
    latestNews,
    currentArticleIndex,
    articlePaginationClicked
  }) {
    const builder = imageUrlBuilder(sanityClient())

    return (
      <Wrapper>
        {latestNews.map((article, index) => {
          return (
            <ArticlePreviewWrapper
              show={currentArticleIndex === index}
              key={article.title}
            >
              <BackgroundImage url={builder.image(article.image).width(1920).quality(55).format('jpg').url()} />
              <CenterWrapper>
                <PurpleShape />
                <OrangeShapeLeft />
                <OrangeShapeRight />
                <DisplayDate>
                  <Slashes>{'//'}</Slashes>
                  {article.published}
                </DisplayDate>
                <PaginationWrapper>
                  {latestNews.map((article, index) => {
                    return (
                      <Pagination
                        key={article.title}
                        active={currentArticleIndex === index}
                        onClick={() => articlePaginationClicked({ index })}
                      >
                        {index + 1}
                      </Pagination>
                    )
                  })}
                </PaginationWrapper>
                <Link href={article.slug ? `news/${article.slug.current}` : `news/${article._id}`}>
                  <Title>{article.title}</Title>
                </Link>
                <TitleDots />
                <Preamble>
                  {article.ingress.length > 110
                    ? article.ingress.substr(0, 110) + '...'
                    : article.ingress}
                </Preamble>
              </CenterWrapper>
            </ArticlePreviewWrapper>
          )
        })}
      </Wrapper>
    )
  }
)
