import styled, { css } from 'styled-components'

const Wrapper = css`
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`

export const WrapperDesktop = styled.div`
  padding-bottom: 70px;
  overflow: hidden;
  margin: 0 auto;
  ${Wrapper} @media(max-width: 767px) {
    display: none;
  }
`

export const WrapperMobile = styled.div`
  ${Wrapper} @media(min-width: 768px) {
    display: none;
  }
  height: 50px;
  background-color: #462087;
  justify-content: flex-end;
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const LogoLink = styled.a`
  height: 130px;
  display: flex;
  align-items: center;
`

export const Logo = styled.div`
  background-image: ${props => `url(${props.url})`};
  width: 227px;
  height: 130px;
  background-size: contain;
  background-repeat: no-repeat;

  @media(max-width: 1045px) {
    margin-left: 22px;
  }
`

export const LinksWrapper = styled.div`
  padding: 0 32px;
  height: 130px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
  font-style: italic;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  margin: 0 8px;
  font-size: 16px;
  line-height: 24px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  font-family: 'TradeGothic18';

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    margin: 0;
  }
`

export const MobileMenuIcon = styled.div`
  color: #fff;
  font-size: 16px;
  padding: 16px;
  line-height: 22px;
  font-family: inherit;
  ::before {
    content: 'Menu'
  }
`

export const PurpleTriangle = styled.div`
  border-bottom: 160px solid #462087;
  border-left: 130px solid transparent;
  border-right: 800px solid transparent;
  height: 0;
  width: 0;
  transform: rotate(-5deg);
  position: absolute;
  top: -50px;
  right: -316px;
  z-index: 0;
`

export const DottedLine = styled.div`
  position: absolute;
  color: #ef6f2e;
  margin-top: 10px;
  font-size: 22px;
  font-weight: normal;

  &:after {
    content:"..........";
  }
`
