import styled from 'styled-components'

export const Wrapper = styled.div`
`

export const ImageWrapper = styled.div``

export const Image = styled.img`
  margin: 16px 0;
  width: 100%;
`

export const ImageAuthor = styled.div`
  font-size: 12px;
  margin-top: -16px;
  font-style: italic;
`
