import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  background-color: #462087;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media(max-width: 767px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 28px;

  @media(max-width: 767px) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
`

export const LinkHeader = styled.div`
  color: #ef6f2e;
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 32px;
  text-transform: uppercase;

  @media(max-width: 767px) {
    margin-bottom: 12px;
    margin-top: 32px;
  }
`

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Link = styled.a`
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  font-weight: bold;
  margin-bottom: 4px;

  &:hover {
    text-decoration: underline;
  }

  @media(max-width: 767px) {
    margin-bottom: 8px;
  }
`

export const PrivacyPolicy = styled.div`
  text-align: center;
  padding-bottom: 80px;

  a {
    color: #ef6f2e;
  }
`
