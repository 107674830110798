import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import { client as sanityClient } from '../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '../../BlockContent'
import Filter from '../../Filter'
import {
  Wrapper,
  ContentWrapper,
  Championship,
  ChampionshipTitle,
  ChampionshipText,
  HeaderImage,
  Background,
  CenterWrapper
} from './elements'

export default connect(
  {
    currentFilter: state`currentFilter`,
    championships: state`championships`
  },
  function Championships ({ currentFilter, championships }) {
    if (!championships.length) {
      return <div></div>
    }

    const builder = imageUrlBuilder(sanityClient())
    const championship = championships.filter((championship, index) => {
      return ((!currentFilter && index === 0) || currentFilter === championship.title)
    })[0] || {}

    return (
      <Wrapper>
        <HeaderImage url={builder.image(championship.image).width(1920).quality(55).format('jpg').url()} />
        <ContentWrapper>
          <Background />
          <CenterWrapper>
            <Filter filters={championships.map((championship) => { return championship.title })}/>
            <Championship key={championship._id}>
              <ChampionshipTitle>{championship.title}</ChampionshipTitle>
              <ChampionshipText>
                <BlockContent block={championship.text} />
              </ChampionshipText>
            </Championship>
          </CenterWrapper>
        </ContentWrapper>
      </Wrapper>
    )
  }
)
