import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral/tags'
import {
  Wrapper,
  TweetWrapper,
  HeaderWrapper,
  Icon,
  Username,
  Image,
  Text,
  Column,
  Link,
  MentionLink,
  VideoPlayButton
} from './elements'

export default connect(
  {
    tweets: state`tweets`,
    tweetsMedia: state`tweetsMedia`
  },
  function Tweets ({ tweets, tweetsMedia }) {
    if (!tweets.length) {
      return <div>Loading tweets...</div>
    }

    function renderTweet (tweet) {
      if (!tweet.entities) tweet.entities = {}
      if (!tweet.entities.mentions) tweet.entities.mentions = []
      if (!tweet.entities.hashtags) tweet.entities.hashtags = []
      if (!tweet.entities.urls) tweet.entities.urls = []
      tweet.entities.mentions = tweet.entities.mentions.map((mention) => { mention.type = 'mention'; return mention })
      tweet.entities.hashtags = tweet.entities.hashtags.map((hashtag) => { hashtag.type = 'hashtag'; return hashtag })
      const tweetUrl = tweet.entities.urls.find((url) => {
        return url.expanded_url.indexOf(tweet.id) >= 0
      })
      tweet.entities.urls = tweet.entities.urls.map((url) => { url.type = 'url'; return url }).filter((url, index) => {
        if (url.expanded_url.indexOf(tweet.id) >= 0) return false
        if (index > 0 && tweet.entities.urls[index - 1].start === url.start) return false
        return true
      })
      const links = tweet.entities.mentions.concat(tweet.entities.hashtags).concat(tweet.entities.urls).sort((a, b) => {
        if (a.start > b.start) {
          return 1
        }
        if (a.start < b.start) {
          return -1
        }
        return 0
      })

      let text = tweet.entities.mentions.length || tweet.entities.urls.length || tweet.entities.hashtags.length ? [] : tweet.text.substr(0, tweetUrl ? tweetUrl.start : tweet.text.length - 24)

      links.forEach((linkObject, index) => {
        const firstText = tweet.text.substr(0, linkObject.start)
        const lastText = tweet.text.substr(
          linkObject.end,
          links[index + 1]
            ? links[index + 1].start - linkObject.end
            : tweet.text.length - 23 - linkObject.end
        )
        if (linkObject.type === 'hashtag') {
          console.log('tweet', tweet)
          const link = (
            <MentionLink
              href={`https://twitter.com/hashtag/${linkObject.tag}?src=hash`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {`#${linkObject.tag}`}
            </MentionLink>
          )
          text.push(
            <span key={linkObject.hashtag}>
              {index === 0 ? <span>{firstText}</span> : null}
              <span>{link}</span>
              <span>{lastText}</span>
            </span>
          )
        }
        if (linkObject.type === 'url') {
          const urlText = tweet.text.substr(linkObject.start, linkObject.end - linkObject.start)
          const link = (
            <MentionLink
              href={linkObject.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {urlText}
            </MentionLink>
          )
          text.push(
            <span key={linkObject.url}>
              {index === 0 ? <span>{firstText}</span> : null}
              <span>{link}</span>
              <span>{lastText}</span>
            </span>
          )
        }

        if (linkObject.type === 'mention') {
          const link = (
            <MentionLink
              href={`https://twitter.com/${linkObject.username}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {`@${linkObject.username}`}
            </MentionLink>
          )
          text.push(
            <span key={`${linkObject.username} ${linkObject.start}`}>
              {index === 0 ? <span>{firstText}</span> : null}
              <span>{link}</span>
              <span>{lastText}</span>
            </span>
          )
        }
      })

      function getMediaUrl (media) {
        if (media.type === 'photo') {
          return media.url
        }

        if (media.type === 'video') {
          return media.preview_image_url
        }

        return null
      }

      const media = tweetsMedia.find((media) => { return media.media_key === tweet.attachments.media_keys[0] })

      return (
        <TweetWrapper key={tweet.id}>
          <HeaderWrapper>
            <Icon />
            <Username
              rel="noopener noreferrer"
              target="_blank"
              href={`https://twitter.com/CoandaEsports/status/${
                tweet.id
              }`}
            >{`@CoandaEsports`}</Username>
          </HeaderWrapper>

          <Link
            target="_blank"
            href={`https://twitter.com/CoandaEsports/status/${tweet.id}`}
          >
            {console.log(tweet)}
            <Image url={getMediaUrl(media)} />
            {media.type === 'video' && <VideoPlayButton>
              <img src="/play.png" width="64"/>
            </VideoPlayButton>}
          </Link>
          <Text>{text}</Text>
        </TweetWrapper>
      )
    }
    const newestTweets = tweets.filter((tweet) => tweet.attachments && tweet.attachments.media_keys).slice(0, 25)
    return (
      <Wrapper>
        <Column>
          {newestTweets.map((tweet, index) => {
            if (index % 3 === 0) {
              return renderTweet(tweet)
            }
            return null
          })}
        </Column>
        <Column>
          {newestTweets.map((tweet, index) => {
            if ((index + 2) % 3 === 0) {
              return renderTweet(tweet)
            }
            return null
          })}
        </Column>
        <Column>
          {newestTweets.map((tweet, index) => {
            if ((index + 1) % 3 === 0) {
              return renderTweet(tweet)
            }
            return null
          })}
        </Column>
      </Wrapper>
    )
  }
)
