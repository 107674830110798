import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #462087;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid #000;
  padding-bottom: 64px;
  z-index: 5;
`

export const CloseButton = styled.div`
  color: #fff;
  font-size: 32px;
  line-height: 24px;
  position: absolute;
  right: 24px;
  top: 24px;

  &::before {
    content: 'X'
  }
`

export const MenuContainer = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background-color: #f4f7f9;
`

export const MenuItem = styled.div`
  font-size: 18px;
  padding-left: 24px;
  color: #fff;
  margin-top: 24px;
`

export const Backdrop = styled.div`
  background-color: rgba(0,0,0,0.7);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
`
