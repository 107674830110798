import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const ContentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: -390px;
  position: relative;
  padding-bottom: 140px;
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 170px auto 0 auto;
  position: relative;
  z-index: 1;

  @media(max-width: 1023px) {
    width: 100%;
    padding: 0 24px;
  }

  @media(max-width: 767px) {
    width: 100%;
    padding: 0 8px;
  }
`

export const HeaderImage = styled.div`
  background-image: ${props => `url(${props.url})`};
  width: 100%;
  height: 120vh;
  min-height: 450px;
  max-height: 1000px;
  background-size: cover;
  background-position: center;

  @media(max-width: 767px) {
    height: 90vh;
  }
`

export const Background = styled.div`
  background: linear-gradient(5.7deg, #ef6f2e 20%, #481f87 93%);
  transform: skewY(-5.7deg);
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: 75px;
`

export const Title = styled.div`
  font-size: 16px;
  line-height: 38px;
  max-width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'TradeGothic18Obl';
  text-align: center;

  @media(max-width: 767px) {
    max-width: 100%;
  }
`

export const MainPartners = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OtherPartners = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 100px;

  @media(max-width: 767px) {
    flex-direction: column;
  }
`

export const MainPartner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0 120px 0;
  max-width: 580px;

  @media(max-width: 767px) {
    max-width: 100%;
  }
`

export const OtherPartner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  max-width: 290px;
  box-sizing: border-box;

  &:only-child {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  &:first-child {
    padding-left: 0;
    padding-right: 32px;
  }

  &:last-child {
    padding-left: 32px;
    padding-right: 0;
  }

  @media(max-width: 767px) {
    max-width: 100%;

    &:only-child {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    &:first-child {
      padding-left: 16px;
      padding-right: 16px;
    }

    &:last-child {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`

export const Logo = styled.a`
  background-image: ${props => `url(${props.url})`};
  width: ${props => props.mainPartner ? '290px' : '160px'};
  height: 70px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

export const Dots = styled.div`
  color: #462087;
  font-size: 18px;
  font-weight: normal;
  margin-top: 22px;
  margin-bottom: 32px;

  &:after {
    content: ${props => props.mainPartner ? '"........................................................"' : '"............................"'};
  }

  @media(max-width: 767px) {
    margin-bottom: 24px;
    margin-top: 18px;
  }
`

export const Description = styled.div`
  font-weight: bold;
  color: #fff;
  font-size: 12px;
  text-align: justify;
  line-height: 18px;

  @media(max-width: 767px) {
    margin-bottom: 48px;
  }
`
