import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import { client as sanityClient } from '../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '../../BlockContent'
import {
  Wrapper,
  ContentWrapper,
  MainPartners,
  OtherPartners,
  Title,
  MainPartner,
  OtherPartner,
  Logo,
  Dots,
  Description,
  HeaderImage,
  Background,
  CenterWrapper
} from './elements'

export default connect(
  {
    partnersData: state`partners`,
    headerImage: state`headerImage`
  },
  function Partners ({ partnersData, headerImage }) {
    if (!partnersData) {
      return <div></div>
    }

    const builder = imageUrlBuilder(sanityClient())
    const mainPartners = partnersData.filter((partner) => {
      return partner.category === 'mainPartner'
    })

    console.log('partnersData', partnersData)

    const partners = partnersData.filter((partner) => {
      return partner.category === 'partner'
    })

    console.log('partners', partners)

    const supporters = partnersData.filter((partner) => {
      return partner.category === 'supporter'
    })

    return (
      <Wrapper>
        <HeaderImage url={builder.image(headerImage).width(1920).quality(55).format('jpg').url()} />
        <ContentWrapper>
          <Background />
          <CenterWrapper>
            <MainPartners>
              <Title>{mainPartners.length > 1 ? 'Main partners' : 'Main partner'}</Title>
              {mainPartners.map((partner) => {
                return (
                  <MainPartner mainPartner={partner.mainPartner} key={partner._id}>
                    <Logo mainPartner={partner.mainPartner} url={builder.image(partner.logo).width(500).quality(55).format('png').url()} href={partner.link} target="_blank" />
                    <Dots mainPartner={partner.mainPartner} />
                    <Description>
                      <BlockContent block={partner.text}/>
                    </Description>
                  </MainPartner>
                )
              })}
            </MainPartners>
            <Title>{partners.length > 1 ? 'Partners' : 'Partner'}</Title>
            <OtherPartners>
              {partners.map((partner) => {
                return (
                  <OtherPartner mainPartner={partner.mainPartner} key={partner._id}>
                    <Logo mainPartner={partner.mainPartner} url={builder.image(partner.logo).width(500).quality(55).format('png').url()} href={partner.link} target="_blank" />
                    <Dots mainPartner={partner.mainPartner} />
                    <Description>
                      <BlockContent block={partner.text}/>
                    </Description>
                  </OtherPartner>
                )
              })}
            </OtherPartners>
            <Title>{supporters.length > 1 ? 'Supporters' : 'Supporter'}</Title>
            <OtherPartners>
              {supporters.map((supporter) => {
                return (
                  <OtherPartner mainPartner={supporter.mainPartner} key={supporter._id}>
                    <Logo mainPartner={supporter.mainPartner} url={builder.image(supporter.logo).width(500).quality(55).format('png').url()} href={supporter.link} target="_blank" />
                    <Dots mainPartner={supporter.mainPartner} />
                    <Description>
                      <BlockContent block={supporter.text}/>
                    </Description>
                  </OtherPartner>
                )
              })}
            </OtherPartners>
          </CenterWrapper>
        </ContentWrapper>
      </Wrapper>
    )
  }
)
