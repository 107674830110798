import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral/tags'
import BlockContent from '../../BlockContent'
import {
  Wrapper,
  ContentWrapper,
  Title,
  Text
} from './elements'

export default connect(
  {
    generalConfig: state`generalConfig`
  },
  function PrivacyPolicy ({ generalConfig }) {
    if (!generalConfig) {
      return (
        <Wrapper>
          <ContentWrapper>
            <Title></Title>
            <Text></Text>
          </ContentWrapper>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <Title>{generalConfig.privacyTitle}</Title>
          <Text>
            <BlockContent block={generalConfig.privacyText}/>
          </Text>
        </ContentWrapper>
      </Wrapper>
    )
  }
)
