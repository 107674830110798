import styled from 'styled-components'

export const Wrapper = styled.div`

`

export const Title = styled.div`
  font-size: 40px;
  line-height: 38px;
  width: 100%;
  font-weight: bold;
  z-index: 1;
  text-transform: uppercase;
  font-family: 'TradeGothic18Obl';
  margin-top: 4px;
  margin-bottom: 8px;
`

export const Slashes = styled.div`
  color: #ef6f2e;
  margin-right: 4px;
`

export const DisplayDate = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;

  @media(max-width: 767px) {
    margin-top: 4px;
  }
`

export const ArticleTextWrapper = styled.div`

`

export const Background = styled.div`
  background: linear-gradient(5.7deg, #ef6f2e 20%, #481f87 93%);
  transform: skewY(-5.7deg);
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: 75px;
`
export const ArticlesSectionWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: -110px;
  position: relative;
  padding-bottom: 140px;
`
export const ArticlesWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 300px;

  @media (max-width: 1045px) {
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
  }

  @media(max-width: 767px) {
    flex-direction: column;
    width: 100%;
    margin-top: 250px;
    padding: 0 8px;
  }
`

export const Image = styled.div`
  height: 200px;
  margin-right: 24px;
  background-image: url(${props => props.src});
  min-width: 355px;
  width: 355px;
  background-size: cover;
  background-position: center;

  @media(max-width: 767px) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
`

export const Article = styled.a`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #fff;
  margin-bottom: 32px;
  align-items: center;

  &:hover {
    > div:first-child {
      background-color: #481f87;
      background-blend-mode: screen;
    }

    > div:last-child > div:nth-child(2) {
      text-decoration: underline;
    }
  }

  @media(max-width: 767px) {
    flex-direction: column;
  }
`

export const Preamble = styled.div`
  font-size: 12px;
  font-weight: bold;
`
