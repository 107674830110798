import React from 'react'
import { connect } from '@cerebral/react'
import { props } from 'cerebral/tags'
import { client as sanityClient } from '../../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import {
  Wrapper,
  BackgroundImage,
  PurpleShape,
  OrangeShapeLeft,
  OrangeShapeRight,
  CenterWrapper,
  Title,
  DisplayDate,
  Slashes,
  TitleDots,
  Preamble,
  ArticlePreviewWrapper,
  Link
} from './elements'

export default connect(
  {
    article: props`article`
  },
  function FirstArticle ({
    article
  }) {
    const builder = imageUrlBuilder(sanityClient())

    return (
      <Wrapper>
        <ArticlePreviewWrapper
          show
          key={article.title}
        >
          <BackgroundImage url={builder.image(article.image).width(1920).quality(55).format('jpg').url()} />
          <CenterWrapper>
            <PurpleShape />
            <OrangeShapeLeft />
            <OrangeShapeRight />
            <DisplayDate>
              <Slashes>{'//'}</Slashes>
              {article.published}
            </DisplayDate>
            <Link href={article.slug ? `news/${article.slug.current}` : `news/${article._id}`}>
              <Title>{article.title}</Title>
            </Link>
            <TitleDots />
            <Preamble>
              {article.ingress.length > 110
                ? article.ingress.substr(0, 110) + '...'
                : article.ingress}
            </Preamble>
          </CenterWrapper>
        </ArticlePreviewWrapper>
      </Wrapper>
    )
  }
)
