import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral/tags'
import { PageWrapper } from './elements'

import Header from '../Header'
import Footer from '../Footer'

import Home from './Home'
import NewsArticle from './NewsArticle'
import News from './News'
import Championships from './Championships'
import Drivers from './Drivers'
import Partners from './Partners'
import Contact from './Contact'
import Gallery from './Gallery'
import PrivacyPolicy from './PrivacyPolicy'
import Impressum from './Impressum'

const pages = {
  newsArticle: NewsArticle,
  news: News,
  championships: Championships,
  drivers: Drivers,
  partners: Partners,
  contact: Contact,
  gallery: Gallery,
  privacyPolicy: PrivacyPolicy,
  impressum: Impressum
}

export default connect(
  {
    page: state`currentPage`
  },
  function App ({ page }) {
    const Page = pages[page]

    return (
      <div>
        <Header />
        <PageWrapper>{Page ? <Page /> : <Home />}</PageWrapper>
        <Footer />
      </div>
    )
  }
)
