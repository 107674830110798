import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  max-height: 1000px;
  position: relative;
  width: 100%;
`

export const BackgroundImage = styled.div`
  background-image: ${props => `url(${props.url})`};
  width: 100%;
  height: 120%;
  position: absolute;
  background-size: cover;
  background-position: center;

  @media(max-width: 767px) {
    height: 100%;
  }
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  max-width: 100%;

  @media(max-width: 767px) {
    width: 100%;
    overflow: hidden;
  }
`

export const PurpleShape = styled.div`
  border-bottom: 260px solid #462087;
  border-left: 1400px solid transparent;
  border-right: 300px solid transparent;
  height: 0;
  width: 0;
  transform: rotate(3deg);
  position: absolute;
  bottom: 10px;
  left: -1210px;

  @media (max-width: 1045px) {
    left: -1170px;
  }

  @media(max-width: 767px) {
    bottom: 120px;
  }
`

export const OrangeShapeLeft = styled.div`
  border-bottom: 30px solid #ef6f2e;
  border-left: 25px solid transparent;
  border-right: 50px solid transparent;
  height: 0;
  width: 0;
  transform: rotate(30deg);
  position: absolute;
  bottom: 190px;
  left: -120px;

  @media(max-width: 767px) {
    bottom: 310px;
    left: 0px;
  }
`

export const OrangeShapeRight = styled.div`
  border-bottom: 30px solid #ef6f2e;
  border-left: 50px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 0;
  transform: rotate(-7deg);
  position: absolute;
  bottom: 40px;
  left: 365px;

  @media (max-width: 1045px) {
    left: 385px;
  }

  @media(max-width: 767px) {
    bottom: 220px;
    right: 20px;
    left: auto;
  }
`

export const Title = styled.div`
  font-size: 40px;
  line-height: 38px;
  max-width: 550px;
  font-weight: bold;
  z-index: 1;
  position: absolute;
  bottom: 100px;
  text-transform: uppercase;
  font-family: 'TradeGothic18Obl';

  @media (max-width: 1045px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media(max-width: 767px) {
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% - 48px);
    max-width: calc(100% - 48px);
    font-size: 24px;
    line-height: 28px;
    bottom: 200px;
  }
`

export const Slashes = styled.div`
  color: #ef6f2e;
  margin-right: 4px;
`

export const DisplayDate = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 180px;

  @media (max-width: 1045px) {
    left: 24px;
  }

  @media(max-width: 767px) {
    left: 24px;
    bottom: 260px;
  }
`

export const TitleDots = styled.div`
  position: absolute;
  color: #ef6f2e;
  font-size: 22px;
  font-weight: normal;
  bottom: 80px;
  font-family: 'Ubuntu';

  &:after {
    content: '....................';
  }

  @media (max-width: 1045px) {
    left: 24px;
  }

  @media(max-width: 767px) {
    bottom: 180px;
    left: 24px;
  }
`

export const Preamble = styled.div`
  position: absolute;
  font-size: 12px;
  bottom: 45px;
  font-weight: bold;
  max-width: 400px;

  @media (max-width: 1045px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media(max-width: 767px) {
    bottom: 135px;
    padding-left: 24px;
    padding-right: 24px;
    max-width: calc(100% - 48px);
  }
`

export const ArticlePreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  opacity: ${props => (props.show ? '1' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
`

export const PaginationWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 120px;
  display: flex;
  flex-direction: row;

  @media(max-width: 767px) {
    right: 0;
    top: 60px;
  }
`

export const Pagination = styled.div`
  font-family: 'Ubuntu';
  font-size: 9px;
  font-weight: bold;
  background-color: ${props => (props.active ? '#ef6f2e' : '#461f87')};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`

export const ShapesWrapper = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 1080px;
  left: 50%;
  margin-left: -540px;
`

export const Link = styled.a`
  color: #fff;
  cursor: pointer;

  &:hover > div {
    text-decoration: underline;
  }
`
