import page from 'page'
import qs from 'query-string'
import * as sequences from './sequences'
import * as providers from './providers'
import * as actions from './actions'
import * as factories from './factories'

export default ({ app }) => {
  app.on('initialized', () => page.start())

  function route (url, sequence) {
    page(url, ({ path, params, querystring }) => {
      // We parse the querystring passed in by page.js and pass it to the sequence
      // on the "query" prop
      const query = qs.parse(querystring)

      app.runSequence(path, sequence, { params, query })
    })

    return ({ props }) => {
      const urlWithReplacedParams = Object.keys(props.params || {}).reduce((currentUrl, param) => {
        return currentUrl.replace(`:${param}`, props.params[param])
      }, url)
      // We stringify any query passed in when the sequence is executed from the
      // view
      const query = props.query ? '?' + qs.stringify(props.query) : ''

      page.show(urlWithReplacedParams + query)
    }
  }

  return {
    state: {
      submitMessage: null,
      currentPage: null,
      isLoading: true,
      currentArticleIndex: 0,
      currentDriverIndex: 0,
      currentPartnerIndex: 0,
      currentArticle: null,
      currentFilter: null,
      mobileMenuIsOpen: false,
      generalConfig: null,
      articles: [],
      drivers: [],
      partners: [],
      tweets: [],
      tweetsMedia: [],
      driverSections: [],
      newsArticles: [],
      championships: [],
      latestNews: [],
      images: [],
      contactForm: {
        name: {
          value: '',
          error: false
        },
        email: {
          value: '',
          error: false
        },
        message: {
          value: '',
          error: false
        }
      }
    },
    sequences: {
      rootRouted: route('/', [factories.renderPage(null), sequences.routeToRoot]),
      driversRouted: route('/drivers', [factories.renderPage('drivers'), sequences.routeToDrivers]),
      newsRouted: route('/news', [factories.renderPage('news'), sequences.routeToNews]),
      newsArticleRouted: route('/news/:id', [factories.renderPage('newsArticle'), sequences.routeToNewsArticle]),
      championshipsRouted: route('/championships', [factories.renderPage('championships'), sequences.routeToChampionships]),
      partnersRouted: route('/partners', [factories.renderPage('partners'), sequences.routeToPartners]),
      contactRouted: route('/contact', [factories.renderPage('contact'), sequences.routeToContact]),
      galleryRouted: route('/gallery', [factories.renderPage('gallery'), sequences.routeToGallery]),
      privacyPolicyRouted: route('/privacy-policy', [factories.renderPage('privacyPolicy'), sequences.routeToPrivacyPolicy]),
      impressumRouted: route('/impressum', [factories.renderPage('impressum'), sequences.routeToImpressum]),

      previousDriverClicked: actions.reduceDriverIndex,
      nextDriverClicked: actions.increaseDriverIndex,
      articlePaginationClicked: sequences.setCurrentArticleIndex,
      fieldChanged: sequences.updateField,
      contactFormSubmitted: sequences.submitForm,
      mobileMenuClicked: sequences.toggleMobileMenu,
      filterClicked: sequences.setCurrentFilter
    },
    providers
  }
}
