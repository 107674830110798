import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const ContentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: -390px;
  position: relative;
  padding-bottom: 140px;
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 170px auto 0 auto;
  position: relative;
  z-index: 1;

  @media (max-width: 1045px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }

  @media(max-width: 767px) {
    width: 100%;
    padding: 0 8px;
  }
`

export const Title = styled.h1`
  font-size: 40px;
  line-height: 38px;
  max-width: 550px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'TradeGothic18Obl';

  @media(max-width: 767px) {
    font-size: 32px;
  }
`
export const DriversWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media(max-width: 767px) {
    flex-direction: column;
  }
`

export const DriverWrapper = styled.div`
  margin-bottom: 42px;
  flex: 0 0 33.33%;
  padding: 12px;

  &:nth-child(3n) {
    padding-right: 0;
    padding-left: 24px;
  }

  &:nth-child(3n + 1), &:first-child {
    padding-right: 24px;
    padding-left: 0;
  }

  @media (max-width: 1045px) {
    flex: 0 0 50%;

    &:nth-child(2n) {
      padding-right: 0;
      padding-left: 24px;
    }

    &:nth-child(2n + 1), &:first-child {
      padding-right: 24px;
      padding-left: 0;
    }
  }

  @media(max-width: 767px) {
    padding: 0;

    &:nth-child(3n) {
      padding-right: 0;
      padding-left: 0;
    }

    &:nth-child(3+1n), &:first-child {
      padding-right: 0;
      padding-left: 0;
    }
  }
`

export const Name = styled.h2`
  font-size: 32px;
  font-weight: bold;
  font-style: italic;
  position: relative;
  margin: 0;
  text-transform: uppercase;
  font-family: 'TradeGothic18';

  @media(max-width: 767px) {
    font-size: 24px;
  }
`

export const NameDots = styled.div`
  position: absolute;
  color: #ef6f2e;
  font-size: 22px;
  font-weight: normal;
  margin-top: -10px;

  &:after {
    content: '....................';
  }
`

export const Country = styled.div`
  border: 2px solid #fff;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-left: 32px;

  @media(max-width: 767px) {
    width: 32px;
    height: 32px;
  }
`

export const NameAndCountry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const DriverImage = styled.img`
  background-image: ${props => `url(${props.url})`};
  width: 100%;
  margin-top: 30px;

  @media(max-width: 767px) {
    flex-direction: column;
  }
`
export const HeaderImage = styled.div`
  background-image: ${props => `url(${props.url})`};
  width: 100%;
  height: 120vh;
  min-height: 450px;
  max-height: 1000px;
  background-size: cover;
  background-position: center;

  @media(max-width: 767px) {
    height: 90vh;
  }
`

export const Background = styled.div`
  background: linear-gradient(5.7deg, #ef6f2e 20%, #481f87 93%);
  transform: skewY(-5.7deg);
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: 75px;
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: 18px;
  z-index: 1;

  &:first-child {
    margin-top: 0;
  }
`
export const Header = styled.div`
  color: #ef6f2e;
  width: 50px;
  font-weight: bold;
  box-sizing: content-box;
`
export const Detail = styled.div`
  font-weight: bold;
  box-sizing: content-box;
  padding-right: 24px;
`
export const ListDetail = styled.div`
  font-weight: bold;
  box-sizing: content-box;
  padding-right: 24px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const List = styled.div`
  flex: 1;
`

export const PurpleShape = styled.div`
  /*border-top: 320px solid #462087;
  border-left: 40px solid transparent;
  border-right: 320px solid #462087;
  transform: rotate(0deg);
  position: absolute;
  height: 100%;
  margin-left: -47px;
  margin-top: 7px;*/
`

export const DetailedInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin-top: 30px;
  background-color: #462087;
  box-sizing: border-box;
  padding: 16px 16px 24px 16px;
`
