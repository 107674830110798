import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import BlockContent from '../../BlockContent'
import {
  Wrapper,
  ContentWrapper,
  Title,
  Image,
  ImageDescription
} from './elements'

export default connect(
  {
    images: state`images`
  },
  function Gallery ({ images }) {
    if (!images.length) {
      return (
        <Wrapper>
          <ContentWrapper>
            <Title>Beauty.</Title>
          </ContentWrapper>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <Title>Beauty.</Title>
          {images.map((image) => {
            return (
              <div key={image.id}>
                <Image
                  src={`https://c1.staticflickr.com/8/${image.server}/${image.id}_${image.secret}_b.jpg`}
                  alt={image.title}
                />
                <ImageDescription>{image.title}</ImageDescription>
              </div>
            )
          })}
        </ContentWrapper>
      </Wrapper>
    )
  }
)
