import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 312px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(5.7deg, #ef6f2e 20%, #481f87 93%);
  padding-bottom: 64px;

  @media(max-width: 767px) {
    padding-top: 100px;
  }
`

export const ContentWrapper = styled.div`
  width: 1024px;
  padding-bottom: 64px;
  max-width: 100%;
  padding: 0 12px;
`

export const Title = styled.h1`
  font-size: 40px;
  line-height: 38px;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'TradeGothic18Obl';
  margin-top: 4px;
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 24px;
`

export const Image = styled.img`
  width: 100%;
  margin-bottom: 4px;
`

export const ImageDescription = styled.div`
  font-size: 12px;
  font-style: italic;
  margin-bottom: 24px;
`
