import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Image = styled.div`
  background-image: ${props => `url(${props.url})`};
  width: 100%;
  height: 120vh;
  min-height: 450px;
  max-height: 1000px;
  background-size: cover;
  background-position: center;

  @media(max-width: 767px) {
    height: 90vh;
  }
`

export const ArticleWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: -330px;
  position: relative;
  padding-bottom: 140px;
`

export const Background = styled.div`
  background: linear-gradient(5.7deg, #ef6f2e 20%, #481f87 93%);
  transform: skewY(-5.7deg);
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: 75px;
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 100px auto 0 auto;
  position: relative;
  z-index: 1;

  @media (max-width: 1045px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }

  @media(max-width: 767px) {
    width: 100%;
    padding: 0 8px;
  }
`

export const Title = styled.h1`
  font-size: 48px;
  line-height: 46px;
  max-width: 550px;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'TradeGothic18Obl';

  @media(max-width: 767px) {
    max-width: 100%;
    font-size: 32px;
    line-height: 32px;
  }
`

export const Slashes = styled.div`
  color: #ef6f2e;
  margin-right: 4px;
`

export const DisplayDate = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`

export const TitleDots = styled.div`
  color: #ef6f2e;
  font-size: 22px;
  font-weight: normal;
  font-family: 'Ubuntu';
  margin-top: -16px;
  margin-bottom: 24px;

  &:after {
    content: '....................';
  }
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: ubuntu;
  width: 640px;

  h2 {
    margin-top: 32px;
    margin-bottom: -8px;
    font-size: 18px;
  }

  @media(max-width: 767px) {
    width: 100%;
  }
`
