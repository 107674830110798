import React from 'react'
import { connect } from '@cerebral/react'
import { state, props, signal } from 'cerebral/tags'
import { Wrapper, FilterTitle, DottedLine } from './elements'

export default connect(
  {
    currentFilter: state`currentFilter`,
    filters: props`filters`,
    filterClicked: signal`filterClicked`
  },
  function Filter ({ currentFilter, filters, filterClicked }) {
    if (!filters.length) {
      return <div></div>
    }
    return (
      <Wrapper>
        {filters.map((filter, index) => {
          return (
            <FilterTitle currentFilter={(!currentFilter && index === 0) || filter === currentFilter} key={filter} onClick={() => filterClicked({ filter: filter })}>
              {filter}
              {(!currentFilter && index === 0) || filter === currentFilter ? <DottedLine /> : null}
            </FilterTitle>
          )
        })}
      </Wrapper>
    )
  }
)
