import { client as sanityClient } from '../sanityClient'
import axios from 'axios'

export const sanity = {
  getDocument (document) {
    return sanityClient().getDocument(document).then(result => {
      return result
    })
  },
  query (query, params) {
    return sanityClient().fetch(query, params).then(result => {
      return result
    })
  }
}

export const http = {
  get (url) {
    return axios.get(url)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log(error)
        return error
      })
  },
  post (url, body) {
    return axios.post(url, body)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log(error)
        return error
      })
  }
}
