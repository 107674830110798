import styled from 'styled-components'

export const Wrapper = styled.div`
  background: linear-gradient(#ef6f2e 20%, #481f87 100%);
  padding: 100px 0px;
  width: 100%;
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media(max-width: 1023px) {
    width: 100%;
    padding: 0 12px;
  }

  @media(max-width: 767px) {
    width: 100%;
    padding: 0 8px;
  }
`

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Title = styled.div`
  margin-right: 28px;
  font-size: 28px;
  font-family: 'TradeGothic18Obl';
  text-transform: uppercase;
  font-weight: bold;
`

export const Dots = styled.div`
  color: #462087;
  font-size: 18px;
  font-weight: normal;
  margin-top: -2px;
  margin-bottom: 52px;

  &:after {
    content: '...........';
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
