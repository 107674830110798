import styled from 'styled-components'
import Arrow from '../../../Arrow'

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: -110px;
  position: relative;
  padding-bottom: 140px;
`

export const Background = styled.div`
  background: linear-gradient(5.7deg, #ef6f2e 20%, #481f87 93%);
  transform: skewY(-5.7deg);
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: 75px;
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media(max-width: 1023px) {
    width: 100%;
    flex-direction: column;
    padding: 0 24px;
  }
`

export const PurpleShape = styled.div`
  border-top: 400px solid #462087;
  border-left: 150px solid transparent;
  border-right: 390px solid #462087;
  height: 0;
  width: 0;
  transform: rotate(0deg);
  position: absolute;
  margin-left: -150px;

  @media(max-width: 1023px) {
    display: none;
  }

`

export const DetailedInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 290px;
  position: relative;
  justify-content: center;
  margin-left: 20px;
  margin-right: 35px;

  @media(max-width: 1023px) {
    margin-top: 32px;
    background-color: #462087;
    padding: 24px 24px;
    margin-left: 0;
    margin-right: 0;
  }
`

export const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-top: 290px;
  margin-right: 40px;

  @media(max-width: 767px) {
    margin-top: 210px;
    margin-right: 0;
    padding-left: 48px;
    padding-right: 48px;
  }
`

export const Name = styled.div`
  font-size: 32px;
  font-weight: bold;
  font-style: italic;
  position: relative;
  text-transform: uppercase;
  font-family: 'TradeGothic18';
`

export const NameDots = styled.div`
  position: absolute;
  color: #ef6f2e;
  font-size: 22px;
  font-weight: normal;
  margin-top: -10px;

  &:after {
    content: '....................';
  }
`

export const Image = styled.img`
  width: 375px;
  margin-top: 30px;

  @media(max-width: 1023px) {
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
  }
`

export const Description = styled.div`
  margin-top: 18px;
  font-weight: bold;
  font-size: 12px;
  max-width: 375px;
  text-align: center;

  @media(max-width: 1023px) {
    max-width: 100%;
    text-align: center;
  }
`

export const QuotationMark = styled.div`
  color: #462087;
  display: inline;
  &:after {
    content: '"';
  }
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  width: 360px;
  margin-top: 18px;
  z-index: 1;

  &:nth-child(2) {
    margin-top: 0;
  }

  @media(max-width: 1023px) {
    width: 100%;
  }
`
export const Header = styled.div`
  color: #ef6f2e;
  width: 50px;
  min-width: 50px;
  font-weight: bold;
`
export const Detail = styled.div`
  font-weight: bold;
`

export const ListDetail = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`

export const List = styled.div``

export const ArrowComp = styled(Arrow)`
  height: 100px;
  width: 70px;
  margin: 0 20px;
  transform: ${props =>
    props.direction === 'right' ? 'rotate(90deg)' : 'rotate(-90deg)'};
  cursor: pointer;

  &:hover {
    svg > polygon {
      stroke: #462087;
    }
  }

  svg > polygon {
    stroke: #ef6f2e;
  }

  @media(max-width: 1120px) {
    margin: 0 40px;
  }

  @media(max-width: 1080px) {
    margin: 0 45px;
  }

  @media(max-width: 1023px) {
    width: 50px;
    height: 70px;
  }
`

export const ArrowWrapper = styled.div`
  position: absolute;
  margin-top: 400px;
  ${props => (props.direction === 'right' ? 'right: 0;' : null)};
  ${props => (props.direction === 'left' ? 'left: 0;' : null)};

  @media(max-width: 1023px) {
    top: 100px;
    z-index: 2;
  }

  @media (max-width: 767px) {
    top: 20px;
  }

  @media (max-width: 630px) {
    top: 0;
  }

  @media (max-width: 550px) {
    top: -30px;
  }

  @media (max-width: 470px) {
    top: -70px;
  }
`

export const ArrowName = styled.div`
  text-transform: uppercase;
  font-family: 'TradeGothic18';
  font-weight: bold;
  font-style: italic;
  white-space: pre;
  font-size: 22px;
  text-align: ${props => (props.direction === 'right' ? 'left' : 'right')};
  position: absolute;
  ${props => (props.direction === 'right' ? 'left: 70px;' : null)};
  ${props => (props.direction === 'left' ? 'right: 70px;' : null)};

  @media(max-width: 1023px) {
    font-size: 14px;
    ${props => (props.direction === 'right' ? 'right: 10px; left: auto;' : null)};
    ${props => (props.direction === 'left' ? 'left: 10px; right: auto;' : null)};
    text-align: ${props => (props.direction === 'right' ? 'right' : 'left')};
  }
`

export const Country = styled.div`
  border: 2px solid #fff;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-left: 32px;
  flex: 0 auto;
  min-width: 42px;
`

export const NameAndCountry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
