import React from 'react';
import { Wrapper, Svg, Triangle } from './elements';

function Arrow({ className, onClick }) {
  return (
    <Wrapper className={className} onClick={onClick}>
      <Svg preserveAspectRatio="none" viewBox="0 50 100 50">
        <Triangle points="5,95 50,50 95,95" className="triangle" />
        Sorry, your browser does not support inline SVG.
      </Svg>
    </Wrapper>
  );
}

export default Arrow;
