import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import { client as sanityClient } from '../../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import {
  Wrapper,
  Background,
  CenterWrapper,
  PurpleShape,
  DetailedInfoWrapper,
  BasicInfoWrapper,
  Name,
  NameDots,
  Image,
  QuotationMark,
  Description,
  DetailsWrapper,
  Header,
  Detail,
  ListDetail,
  List,
  ArrowComp,
  ArrowWrapper,
  ArrowName,
  Country,
  NameAndCountry
} from './elements'

export default connect(
  {
    drivers: state`drivers`,
    driver: state`drivers.${state`currentDriverIndex`}`,
    currentDriverIndex: state`currentDriverIndex`,
    previousDriverClicked: signal`previousDriverClicked`,
    nextDriverClicked: signal`nextDriverClicked`
  },
  function DriversSection ({
    isSmall,
    drivers,
    driver,
    currentDriverIndex,
    previousDriverClicked,
    nextDriverClicked
  }) {
    function getValidIndex (index) {
      if (index > drivers.length - 1) {
        return 0
      }

      if (index < 0) {
        return drivers.length - 1
      }

      return index
    }

    if (!drivers.length) {
      return <div></div>
    }

    function getAge (birthday) {
      const year = birthday.split('-')[0]
      const month = birthday.split('-')[1]
      const date = birthday.split('-')[2]

      var ageDifMs = Date.now() - new Date(year, month - 1, date).getTime()
      var ageDate = new Date(ageDifMs) // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    }

    const builder = imageUrlBuilder(sanityClient())

    return (
      <Wrapper>
        <Background />
        <CenterWrapper>
          <ArrowWrapper direction="left">
            <ArrowComp
              onClick={() => previousDriverClicked()}
              direction="left"
            />
            <ArrowName direction="left">
              {drivers[getValidIndex(currentDriverIndex - 1)].name.replace(
                ' ',
                '\n'
              )}
            </ArrowName>
          </ArrowWrapper>
          <BasicInfoWrapper>
            <Name>
              <NameAndCountry>{driver.name} <Country src={builder.image(driver.nationality.flag).width(150).quality(55).format('jpg').url()}/></NameAndCountry>
              <NameDots />
            </Name>
            <Image src={builder.image(driver.image).width(500).quality(55).format('png').url()} />
            <Description>
              <QuotationMark />
              {driver.description}
              <QuotationMark />
            </Description>
          </BasicInfoWrapper>
          <DetailedInfoWrapper>
            <PurpleShape />
            <DetailsWrapper>
              <Header>{'Born'}</Header>
              <Detail>{driver.born}</Detail>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Home'}</Header>
              <Detail>{driver.hometown}</Detail>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Age'}</Header>
              <Detail>{getAge(driver.dateOfBirth)}</Detail>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Joined'}</Header>
              <Detail>{driver.joined}</Detail>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Series'}</Header>
              <List>
                {driver.series.map((series, index) => (
                  <ListDetail key={`${series}_${index}`}>{series}</ListDetail>
                ))}
              </List>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Titles'}</Header>
              <List>
                {driver.titles.map((title, index) => (
                  <ListDetail key={`${driver.name}_${title}_${index}`}>{title}</ListDetail>
                ))}
              </List>
            </DetailsWrapper>
          </DetailedInfoWrapper>
          <ArrowWrapper direction="right">
            <ArrowComp direction="right" onClick={() => nextDriverClicked()} />
            <ArrowName direction="right">
              {drivers[getValidIndex(currentDriverIndex + 1)].name.replace(
                ' ',
                '\n'
              )}
            </ArrowName>
          </ArrowWrapper>
        </CenterWrapper>
      </Wrapper>
    )
  }
)
