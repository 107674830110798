import styled from 'styled-components';

export const Svg = styled.svg`
  enable-background: new 0 0 455.731 455.731;
  background-color: #50abf1;
`;

export const Rect = styled.rect`
  fill: #50abf1;
`;

export const Path = styled.path`
  fill: #ffffff;
`;
