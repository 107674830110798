import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral/tags'
import { Wrapper } from './elements'
import NewsSection from './NewsSection'
import DriversSection from './DriversSection'
import PartnersSection from './PartnersSection'
import SocialMediaSection from './SocialMediaSection'

export default connect(
  {
    isSmall: state`useragent.media.small`
  },
  function Home ({ isSmall }) {
    return (
      <Wrapper>
        <NewsSection />
        <DriversSection />
        <PartnersSection />
        <SocialMediaSection />
      </Wrapper>
    )
  }
)
