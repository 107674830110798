import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral/tags'
import { Wrapper, Column, LinkHeader, LinksWrapper, Link, PrivacyPolicy } from './elements'

export default connect(
  {
    driverSections: state`driverSections`,
    championships: state`championships`,
    partners: state`partners`,
    latestNews: state`latestNews`
  },
  function Footer ({ driverSections, championships, partners, latestNews }) {
    if (!driverSections.length || !championships.length || !partners.length || !latestNews.length) {
      return <div></div>
    }
    return (
      <div>
        <Wrapper>
          <Column>
            <LinkHeader>Drivers</LinkHeader>
            <LinksWrapper>
              {driverSections.map(driverSection => {
                return (
                  <Link
                    key={driverSection.title}
                    href={`/drivers?filter=${encodeURIComponent(driverSection.title)}`}
                  >
                    {driverSection.title}
                  </Link>
                )
              })}
            </LinksWrapper>
          </Column>
          <Column>
            <LinkHeader>Championships</LinkHeader>
            <LinksWrapper>
              {championships.map(championship => {
                return (
                  <Link key={championship._id} href={`/championships?filter=${encodeURIComponent(championship.title)}`}>
                    {championship.title}
                  </Link>
                )
              })}
            </LinksWrapper>
          </Column>
          <Column>
            <LinkHeader>Partners</LinkHeader>
            <LinksWrapper>
              {partners.map(partner => {
                return (
                  <Link key={partner._id} href={`/partners?filter=${encodeURIComponent(partner.name)}`}>
                    {partner.name}
                  </Link>
                )
              })}
            </LinksWrapper>
          </Column>
          <Column>
            <LinkHeader>Latest news</LinkHeader>
            <LinksWrapper>
              {latestNews.map(newsArticle => {
                return (
                  <Link key={newsArticle._id} href={newsArticle.slug ? `/news/${newsArticle.slug.current}` : `/news/${newsArticle._id}`}>
                    {newsArticle.title}
                  </Link>
                )
              })}
            </LinksWrapper>
          </Column>
          <Column>
            <LinkHeader>Other</LinkHeader>
            <LinksWrapper>
              <Link href={`/gallery`}>
              Gallery
              </Link>
              <Link href={`/contact`}>
              Contact
              </Link>
            </LinksWrapper>
          </Column>
        </Wrapper>
        <PrivacyPolicy>Coanda Esports © All rights reserved. Please read our <a href="/privacy-policy">privacy policy</a> and <a href="/impressum">impressum</a>.</PrivacyPolicy>
      </div>
    )
  }
)
