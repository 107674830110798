import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import { client as sanityClient } from '../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import FirstArticle from './FirstArticle'
import {
  Wrapper,
  Title,
  DisplayDate,
  Slashes,
  Background,
  ArticleTextWrapper,
  ArticlesWrapper,
  ArticlesSectionWrapper,
  Image,
  Article,
  Preamble
} from './elements'

export default connect(
  {
    newsArticles: state`newsArticles`,
    currentArticleIndex: state`currentArticleIndex`,
    articlePaginationClicked: signal`articlePaginationClicked`
  },
  function News ({
    isSmall,
    newsArticles,
    currentArticleIndex,
    articlePaginationClicked
  }) {
    if (!newsArticles.length) {
      return (
        <div></div>
      )
    }
    const builder = imageUrlBuilder(sanityClient())

    return (
      <Wrapper>
        <FirstArticle article={newsArticles[0]} />
        <ArticlesSectionWrapper>
          <Background />
          <ArticlesWrapper>
            {newsArticles.slice(1).map((article) => {
              return (
                <Article key={article._id} href={article.slug ? `news/${article.slug.current}` : `news/${article._id}`}>
                  <Image src={builder.image(article.image).width(800).quality(50).format('jpg').url()} />
                  <ArticleTextWrapper>
                    <DisplayDate>
                      <Slashes>{'//'}</Slashes>
                      {article.published}
                    </DisplayDate>
                    <Title>{article.title}</Title>
                    <Preamble>{article.ingress}</Preamble>
                  </ArticleTextWrapper>
                </Article>
              )
            })}
          </ArticlesWrapper>
        </ArticlesSectionWrapper>
      </Wrapper>
    )
  }
)
