import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import { client as sanityClient } from '../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '../../BlockContent'
import Filter from '../../Filter'
import {
  Wrapper,
  ContentWrapper,
  Title,
  DriversWrapper,
  DriverWrapper,
  Name,
  NameAndCountry,
  NameDots,
  Country,
  DriverImage,
  DetailedInfoWrapper,
  DetailsWrapper,
  Header,
  Detail,
  ListDetail,
  List,
  Background,
  HeaderImage,
  CenterWrapper
} from './elements'

export default connect(
  {
    currentFilter: state`currentFilter`,
    driverSections: state`driverSections`,
    drivers: state`drivers`
  },
  function Drivers ({ currentFilter, driverSections, drivers }) {
    if (!driverSections.length || !drivers.length) {
      return <div></div>
    }

    function getAge (birthday) {
      const year = birthday.split('-')[0]
      const month = birthday.split('-')[1]
      const date = birthday.split('-')[2]

      var ageDifMs = Date.now() - new Date(year, month - 1, date).getTime()
      var ageDate = new Date(ageDifMs) // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    }

    function renderDriver (driver) {
      return (
        <DriverWrapper key={driver._id}>
          <Name>
            <NameAndCountry>{driver.name} <Country src={builder.image(driver.nationality.flag).width(400).quality(55).format('jpg').url()}/></NameAndCountry>
            <NameDots />
          </Name>
          <DriverImage src={builder.image(driver.image).width(500).quality(55).format('png').url()} />
          <DetailedInfoWrapper>
            <DetailsWrapper>
              <Header>{'Born'}</Header>
              <Detail>{driver.born}</Detail>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Home'}</Header>
              <Detail>{driver.hometown}</Detail>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Age'}</Header>
              <Detail>{getAge(driver.dateOfBirth)}</Detail>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Joined'}</Header>
              <Detail>{driver.joined}</Detail>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Series'}</Header>
              <List>
                {driver.series.map((series, index) => (
                  <ListDetail key={`${series}_${index}`}>{series}</ListDetail>
                ))}
              </List>
            </DetailsWrapper>
            <DetailsWrapper>
              <Header>{'Titles'}</Header>
              <List>
                {driver.titles.map((title, index) => (
                  <ListDetail key={`${driver.name}_${title}_${index}`}>{title}</ListDetail>
                ))}
              </List>
            </DetailsWrapper>
          </DetailedInfoWrapper>
        </DriverWrapper>
      )
    }

    const builder = imageUrlBuilder(sanityClient())
    const driverSection = driverSections.filter((driverSection, index) => {
      return ((!currentFilter && index === 0) || currentFilter === driverSection.title)
    })[0]
    const driverIds = driverSection.drivers.map((driver) => {
      return driver._ref
    })

    const sectionDrivers = drivers.filter((driver) => {
      return driverIds.indexOf(driver._id) >= 0
    })
    return (
      <Wrapper>
        <HeaderImage url={builder.image(driverSection.image).width(1920).quality(55).format('jpg').url()} />
        <ContentWrapper>
          <Background />
          <CenterWrapper>
            <Filter filters={driverSections.map((driverSection) => { return driverSection.title })}/>
            <DriversWrapper>
              {sectionDrivers.map((driver, index) => {
                return renderDriver(driver)
              })}
            </DriversWrapper>
          </CenterWrapper>
        </ContentWrapper>
      </Wrapper>
    )
  }
)
