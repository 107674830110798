import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral/tags'
import { client as sanityClient } from '../../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '../../../BlockContent'
import {
  Wrapper,
  CenterWrapper,
  PartnerWrapper,
  Logo,
  Dots,
  Description,
  PartnerHeader
} from './elements'

export default connect(
  {
    partners: state`partners`,
    currentPartnerIndex: state`currentPartnerIndex`
  },
  function PartnersSection ({
    isSmall,
    partners,
    partner,
    currentPartnerIndex
  }) {
    function renderPartner (partner) {
      if (!partner) {
        return null
      }

      return (
        <PartnerWrapper mainPartner={partner.mainPartner} key={partner._id}>
          <Logo mainPartner={partner.mainPartner} url={builder.image(partner.logo).width(500).quality(55).format('png').url()} href={partner.link} target="_blank" />
          <Dots mainPartner={partner.mainPartner} />
          <Description>
            <BlockContent block={partner.description}/>
          </Description>
        </PartnerWrapper>
      )
    }

    function getValidIndex (index) {
      return index > partners.length - 1 ? index - partners.length : index
    }

    if (!partners.length) {
      return <div></div>
    }

    const builder = imageUrlBuilder(sanityClient())
    const mainPartners = partners.filter((partner) => {
      return partner.mainPartner
    })

    const otherPartners = partners.filter((partner) => {
      return !partner.mainPartner
    })

    return (
      <Wrapper>
        <PartnerHeader>{mainPartners.length > 1 ? 'Main partners' : 'Main partner'}</PartnerHeader>
        <CenterWrapper>
          {mainPartners.map((partner) => {
            return renderPartner(partner)
          })}
        </CenterWrapper>

        <PartnerHeader>{otherPartners.length > 1 ? 'Supporters' : 'Supporter'}</PartnerHeader>
        <CenterWrapper>
          {renderPartner(otherPartners[getValidIndex(currentPartnerIndex)])}
          {renderPartner(otherPartners[getValidIndex(currentPartnerIndex + 1)])}
          {renderPartner(otherPartners[getValidIndex(currentPartnerIndex + 2)])}
        </CenterWrapper>
      </Wrapper>
    )
  }
)
