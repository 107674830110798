import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 64px;

  @media(max-width: 767px) {
    flex-direction: column;
  }
`

export const FilterTitle = styled.div`
  padding: 0px 8px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  font-family: 'TradeGothic18';
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  flex: 1;
  width: 100%;
  ${props => props.currentFilter ? 'font-weight: bold;' : null}

  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`

export const DottedLine = styled.div`
  position: absolute;
  color: #ef6f2e;
  margin-top: -12px;
  font-size: 22px;
  font-weight: normal;

  &:after {
    content:"...................";
  }
`
