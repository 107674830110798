import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral/tags'
import { client as sanityClient } from '../../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '../../BlockContent'
import {
  Wrapper,
  Image,
  Background,
  ArticleWrapper,
  CenterWrapper,
  DisplayDate,
  Slashes,
  Title,
  TitleDots,
  Text
} from './elements'
import { formatDate } from '../../../utils/common'

export default connect(
  {
    article: state`currentArticle`
  },
  function NewsArticle ({ article }) {
    const builder = imageUrlBuilder(sanityClient())

    if (!article) {
      return <Wrapper>
        <Image />
        <ArticleWrapper>
          <Background />
          <CenterWrapper>
            <DisplayDate>
              <Slashes>{'//'}</Slashes>
            </DisplayDate>
            <Title></Title>
            <TitleDots />
            <Text></Text>
          </CenterWrapper>
          <Title />
        </ArticleWrapper>
      </Wrapper>
    }

    return (
      <Wrapper>
        <Image url={builder.image(article.image).width(1920).quality(55).format('jpg').url()} />
        <ArticleWrapper>
          <Background />
          <CenterWrapper>
            <DisplayDate>
              <Slashes>{'//'}</Slashes>
              {formatDate(article.datetime)}
            </DisplayDate>
            <Title>{article.title}</Title>
            <TitleDots />
            <Text>
              <BlockContent block={article.text}/>
            </Text>
          </CenterWrapper>
        </ArticleWrapper>
      </Wrapper>
    )
  }
)
