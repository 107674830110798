import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 300px;
  height: 300px;
`;

export const Svg = styled.svg``;

export const Triangle = styled.polygon`
  fill: transparent;
  stroke: white;
  stroke-width: 3;
  transition: all 0.2s ease-in-out;
`;
