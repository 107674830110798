import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import {
  Container,
  CloseButton,
  MenuContainer,
  MenuItem,
  Backdrop
} from './elements'

export default connect(
  {
    currentPage: state`appContainer.currentPage`,
    activePage: state`onboarding.page`,
    currentEndpoint: state`appContainer.currentEndpoint`,
    mobileMenuClicked: signal`mobileMenuClicked`
  },
  function NavMen1 ({
    mobileMenuClicked,
    currentEndpoint,
    activePage,
    currentPage
  }) {
    return (
      <div>
        <Container>
          <CloseButton
            onClick={() => mobileMenuClicked()}
          />
          <MenuContainer>
            <a href={`/`}>
              <MenuItem activePage={!activePage}>Home</MenuItem>
            </a>
            <a href={`/news`}>
              <MenuItem activePage={activePage === 'news'}>News</MenuItem>
            </a>
            <a href={`/championships`}>
              <MenuItem activePage={activePage === 'championships'}>Championships</MenuItem>
            </a>
            <a href={`/drivers`}>
              <MenuItem activePage={activePage === 'drivers'}>Drivers</MenuItem>
            </a>
            <a href={`/gallery`}>
              <MenuItem activePage={activePage === 'gallery'}>Gallery</MenuItem>
            </a>
            <a href={`/partners`}>
              <MenuItem activePage={activePage === 'partners'}>Partners</MenuItem>
            </a>
            <a href={`/contact`}>
              <MenuItem activePage={activePage === 'contact'}>Contact</MenuItem>
            </a>
          </MenuContainer>
        </Container>
        <Backdrop onClick={() => mobileMenuClicked()}/>
      </div>
    )
  }
)
