import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral/tags'
import {
  Wrapper,
  CenterWrapper,
  TitlesWrapper,
  Title,
  Dots,
  ContentWrapper
} from './elements'

import Tweets from './Tweets'

export default connect(
  {
    tweets: state`tweets`
  },
  function SocialMediaSection ({ tweets }) {
    return (
      <Wrapper>
        <CenterWrapper>
          <TitlesWrapper>
            <Title active>
              Twitter
              <Dots />
            </Title>
          </TitlesWrapper>
          <ContentWrapper>
            <Tweets />
          </ContentWrapper>
        </CenterWrapper>
      </Wrapper>
    )
  }
)
