import styled from 'styled-components'
import TwitterIcon from '../../../../TwitterIcon'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media(max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`

export const TweetWrapper = styled.div`
  margin-bottom: 16px;
  background-color: #461f87;
`

export const HeaderWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const Icon = styled(TwitterIcon)`
  width: 80px;
  height: 65px;
`

export const Username = styled.a`
  color: #000;
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
  font-family: Ubuntu;
  margin-left: 24px;

  &:hover {
    text-decoration: underline;
  }
`

export const Image = styled.div`
  width: 100%;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.url})`};
  background-position: center;
`

export const Text = styled.div`
  padding: 12px;
  color: #fff;
  font-size: 14px;
  font-style: italic;
  overflow-wrap: break-word;
  white-space: pre-line;
`

export const Column = styled.div`
  width: 33.33%;
  padding: 4px;
  box-sizing: border-box;

  &:first-child {
    padding-left: 0;
    padding-right: 8px;
  }

  &:last-child {
    padding-left: 8px;
    padding-right: 0;
  }

  @media(max-width: 767px) {
    width: 100%;
    padding: 0;

    &:first-child {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export const Link = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    img {
      transform: scale(1);
      opacity: 1;
    }
  }
`

export const MentionLink = styled.a`
  color: #00baff;

  &:hover {
    text-decoration: underline;
  }
`
export const VideoPlayButton = styled.div`
  position: absolute;

  img {
    transition: transform 250ms, opacity 250ms;
    opacity: 0.8;
    transform: scale(0.8);
  }
`
