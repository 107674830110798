import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const ContentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: -390px;
  position: relative;
  padding-bottom: 140px;
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 170px auto 0 auto;
  position: relative;
  z-index: 1;

  @media (max-width: 1045px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }

  @media(max-width: 767px) {
    width: 100%;
    padding: 0 8px;
  }
`

export const HeaderImage = styled.div`
  background-image: ${props => `url(${props.url})`};
  width: 100%;
  height: 120vh;
  min-height: 450px;
  max-height: 1000px;
  background-size: cover;
  background-position: center;

  @media(max-width: 767px) {
    height: 90vh;
  }
`

export const Background = styled.div`
  background: linear-gradient(5.7deg, #ef6f2e 20%, #481f87 93%);
  transform: skewY(-5.7deg);
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: 75px;
`

export const Championship = styled.div`
  margin-bottom: 64px;
`

export const ChampionshipTitle = styled.h1`
  font-size: 40px;
  line-height: 38px;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'TradeGothic18Obl';
  margin-top: 4px;
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media(max-width: 767px) {
    font-size: 24px;
    line-height: 24px;
  }
`

export const ChampionshipText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: ubuntu;
  width: 640px;

  @media(max-width: 767px) {
    width: 100%;
  }
`
