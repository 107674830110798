import { state, props } from 'cerebral/tags'
import { set } from 'cerebral/operators'
import * as actions from './actions'

export function renderPage (pageName) {
  return [
    actions.scrollToTop,
    set(state`isLoading`, true),
    set(state`mobileMenuIsOpen`, false),
    set(state`currentFilter`, null),
    set(state`currentPage`, pageName),
    actions.getFrontpageData,
    set(state`driverSections`, props`frontpageData.driverSections`),
    set(state`partners`, props`frontpageData.partners`),
    set(state`championships`, props`frontpageData.championships`),
    set(state`latestNews`, props`frontpageData.latestNews`)
  ]
}
