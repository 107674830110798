const sanityClient = require('@sanity/client')
let initialisedClient = null

export function init () {
  initialisedClient = sanityClient({
    projectId: 'dg9zl19c',
    dataset: 'production',
    token: '', // or leave blank to be anonymous user
    useCdn: true // `false` if you want to ensure fresh data
  })
}

export function client () {
  return initialisedClient
}
