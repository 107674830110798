function shuffle (array) {
  let currentIndex = array.length; let temporaryValue; let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export function getGeneralConfig ({ state, sanity }) {
  const documentId = 'general-config'
  return sanity.query(`*[_type == "general" && _id == "${documentId}"] {...}`).then((documents) => {
    return { document: documents[0] }
  })
}

export function getDocuments ({ state, sanity }) {
  return sanity.query('*[_type == "recommendation"] {title, _id}').then((documents) => {
    return { documents }
  })
}

export function getDocument ({ state, sanity }) {
  const documentId = state.get('currentDocumentId')
  return sanity.query(`*[_type == "recommendation" && _id == "${documentId}"] {..., "contentTypes": *[ _type == "contentType" && _id in ^.contentTypes[]._ref ]}`).then((documents) => {
    return { document: documents[0] }
  })
}

export function getHeaderImage ({ state, sanity }) {
  const currentPage = state.get('currentPage')

  return sanity.query(`*[_type == "pages" && name match "${currentPage}"] {image}`).then((headerImage) => {
    return { headerImage: headerImage[0].image }
  })
}

export function getNews ({ state, sanity }) {
  return sanity.query(`*[_type == "newsArticles"] | order(published desc) {_id, published, image, ingress, title, slug}`).then((newsArticles) => {
    return { newsArticles }
  })
}

export function getDrivers ({ state, sanity }) {
  return sanity.query(`*[_type == "drivers"] {..., "nationality": *[ _type == "countries" && _id == ^.nationality._ref ][0]}`).then((drivers) => {
    return { drivers: shuffle(drivers) }
  })
}

export function getFrontpageData ({ state, sanity }) {
  return sanity.query(`{
    "driverSections": *[_type == "driverSections"] | order(order) {...},
    "partners": *[_type == "sponsors"] | order(order),
    "championships": *[_type == "championships"] | order(order) {...},
    "latestNews": *[_type == "newsArticles"] | order(published desc) {_id, published, image, ingress, title, slug}[0...5]
  }`).then((frontpageData) => {
    return { frontpageData }
  })
}

export function getNewsArticle ({ state, sanity }) {
  const articleId = state.get('currentArticleId')

  return sanity.query(`
    *[_type == "newsArticles" && (_id == "${articleId}" || slug.current == "${articleId}")] {
      ...,
      text[] {
        ...,
        _type == "image" => {
          ...,
          asset->
        }
      }
    }
    `).then((articles) => {
    return { article: articles[0] }
  })
}

export function reduceDriverIndex ({ state }) {
  const currentDriverIndex = state.get('currentDriverIndex')
  const lastDriversIndex = state.get('drivers').length - 1
  const newDriverIndex = currentDriverIndex === 0
    ? lastDriversIndex
    : currentDriverIndex - 1

  state.set('currentDriverIndex', newDriverIndex)
}

export function increaseDriverIndex ({ state }) {
  const currentDriverIndex = state.get('currentDriverIndex')
  const lastDriversIndex = state.get('drivers').length - 1
  const newDriverIndex = currentDriverIndex === lastDriversIndex
    ? 0
    : currentDriverIndex + 1

  state.set('currentDriverIndex', newDriverIndex)
}

export function getTweets ({ state, http }) {
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://coanda-simsport.herokuapp.com' : 'http://localhost:9000'

  return http.get(`${baseUrl}/getTweets`).then((response) => {
    if (!response.data) {
      return { tweets: [], tweetsMedia: [] }
    }

    return { tweets: response.data.tweets, tweetsMedia: response.data.media }
  }).catch(() => {
    return { tweets: [], tweetsMedia: [] }
  })
}

export function getImages ({ state, http }) {
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://coanda-simsport.herokuapp.com' : 'http://localhost:9000'

  return http.get(`${baseUrl}/flickrImages`).then((response) => {
    if (!response.data) {
      return { images: [] }
    }
    return { images: response.data.images }
  }).catch(() => {
    return { images: [] }
  })
}

export function submitForm ({ state, http, path }) {
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://coanda-simsport.herokuapp.com' : 'http://localhost:9000'
  const contactForm = state.get('contactForm')
  console.log('POSTING')
  return http.post(`${baseUrl}/contact`, {
    name: contactForm.name.value,
    email: contactForm.email.value,
    message: contactForm.message.value
  }).then((response) => {
    console.log('RESPONSE', response)
    return path.success()
  }).catch(() => {
    return path.error()
  })
}

export function formIsValid ({ state, path }) {
  const contactForm = state.get('contactForm')
  let isValid = Object.keys(contactForm).filter((field) => {
    return contactForm[field].error
  }).length === 0

  console.log(isValid)

  if (isValid) {
    return path.true()
  } else {
    return path.false()
  }
}

export function setErrorState ({ state, path }) {
  const contactForm = state.get('contactForm')

  Object.keys(contactForm).forEach((field) => {
    if (!contactForm[field].value.length) {
      state.set(`contactForm.${field}.error`, true)
    }
  })
}

export function updateErrorState ({ state, props }) {
  if (props.value.length > 0) {
    state.set(`contactForm.${props.field}.error`, false)
  } else {
    state.set(`contactForm.${props.field}.error`, true)
  }
}

export function setRandomDriverIndex ({ state }) {
  const totalDrivers = state.get('drivers').length

  state.set('currentDriverIndex', Math.floor(Math.random() * totalDrivers))
}

export function scrollToTop () {
  window.scrollTo(0, 0)
}

export function setCurrentFilter ({ props, state }) {
  state.set('currentFilter', decodeURIComponent(props.query.filter))
}

export function setFilterUrl ({ props, state }) {
  const currentFilter = state.get('currentFilter')

  window.history.pushState({
    currentFilter: currentFilter,
    path: `${window.location.pathname}?filter=${currentFilter}`
  }, currentFilter, `${window.location.pathname}?filter=${currentFilter}`)
}
