function makeTwoDigit (number) {
  return number >= 10 ? number : '0' + number
}

export function formatDate (datetime = Date.now()) {
  const dateObject = new Date(datetime)
  const date = makeTwoDigit(dateObject.getDate())
  const month = makeTwoDigit(dateObject.getMonth() + 1)
  const year = dateObject.getFullYear()

  return `${date}.${month}.${year}`
};
