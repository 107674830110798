import React from 'react'
import SanityBlockContent from '@sanity/block-content-to-react'
import { Wrapper, Image, ImageWrapper, ImageAuthor } from './elements'
import { client as sanityClient } from '../../sanityClient'
import imageUrlBuilder from '@sanity/image-url'

function BlockContent ({ block, className }) {
  const builder = imageUrlBuilder(sanityClient())
  console.log('hello???', block)
  const serializers = {
    types: {
      image: function image (props) {
        return (
          <ImageWrapper>
            <Image src={builder.image(props.node.asset).width(900).quality(55).format('jpg').url()} alt={props.node.alt} />
            <ImageAuthor>{props.node.alt} Photo: {props.node.author}.</ImageAuthor>
          </ImageWrapper>
        )
      },
      code: function code (props) {
        return (
          <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
          </pre>
        )
      }
    },
    marks: {
      link: function link (props) {
        return (
          <a href={props.mark.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
        )
      }
    }
  }

  return (
    <Wrapper className={className}>
      <SanityBlockContent blocks={block} serializers={serializers} />
    </Wrapper>
  )
}

export default BlockContent
