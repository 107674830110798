import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #462087;
  padding: 100px 0px;
  width: 100%;
`

export const CenterWrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media(max-width: 1023px) {
    width: 100%;
  }

  @media(max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
`

export const PartnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${props => props.mainPartner ? '24px 32px 120px 32px' : '32px'};
  max-width: ${props => props.mainPartner ? '490px' : '290px'};

  @media(max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`

export const Logo = styled.a`
  background-image: ${props => `url(${props.url})`};
  width: ${props => props.mainPartner ? '290px' : '160px'};
  height: 70px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media(max-width: 767px) {
    width: 100%;
  }
`

export const Dots = styled.div`
  color: #ef6f2e;
  font-size: 18px;
  font-weight: normal;
  margin-top: 22px;
  margin-bottom: 52px;

  &:after {
    content: ${props => props.mainPartner ? '"........................................................"' : '"............................"'};
  }
`

export const Description = styled.div`
  font-weight: bold;
  color: #fff;
  font-size: 12px;
  text-align: justify;
  line-height: 18px;
`

export const PartnerHeader = styled.div`
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 12px;
`
