import { state, props } from 'cerebral/tags'
import { set, toggle, when } from 'cerebral/operators'
import * as actions from './actions'

export const routeToRoot = [
  actions.getDrivers,
  set(state`drivers`, props`drivers`),
  actions.setRandomDriverIndex,
  actions.getTweets,
  set(state`tweets`, props`tweets`),
  set(state`tweetsMedia`, props`tweetsMedia`),
  set(state`isLoading`, false)
]

export const routeToDrivers = [
  actions.getDrivers,
  set(state`drivers`, props`drivers`),
  set(state`isLoading`, false),
  when(props`query.filter`), {
    true: [
      actions.setCurrentFilter
    ],
    false: []
  }
]

export const routeToChampionships = [
  set(state`isLoading`, false),
  when(props`query.filter`), {
    true: [
      actions.setCurrentFilter
    ],
    false: []
  }
]

export const routeToPrivacyPolicy = [
  actions.getGeneralConfig,
  set(state`generalConfig`, props`document`),
  set(state`isLoading`, false)
]

export const routeToImpressum = [
  actions.getGeneralConfig,
  set(state`generalConfig`, props`document`),
  set(state`isLoading`, false)
]

export const routeToPartners = [
  actions.getHeaderImage,
  set(state`headerImage`, props`headerImage`),
  set(state`isLoading`, false)
]

export const routeToContact = [
  set(state`isLoading`, false)
]

export const routeToNews = [
  actions.getNews,
  set(state`newsArticles`, props`newsArticles`),
  set(state`isLoading`, false)
]

export const routeToNewsArticle = [
  set(state`currentArticleId`, props`params.id`),
  actions.getNewsArticle,
  set(state`currentArticle`, props`article`),
  set(state`isLoading`, false)
]

export const routeToGallery = [
  actions.getImages,
  set(state`images`, props`images`),
  set(state`isLoading`, false)
]

export const setCurrentArticleIndex = [
  set(state`currentArticleIndex`, props`index`)
]

export const toggleMobileMenu = [
  toggle(state`mobileMenuIsOpen`)
]

export const updateField = [
  set(state`contactForm.${props`field`}.value`, props`value`),
  actions.updateErrorState
]

export const submitForm = [
  actions.formIsValid, {
    true: [
      actions.submitForm, {
        success: [
          set(state`submitMessage`, 'Thank you for your message, we will get back to you.'),
          set(state`contactForm`, {
            name: {
              value: '',
              error: false
            },
            email: {
              value: '',
              error: false
            },
            message: {
              value: '',
              error: false
            }
          })
        ],
        error: [
          set(state`submitMessage`, 'Could not send message, please try again!')
        ]
      }
    ],
    false: [actions.setErrorState]
  }
]

export const setCurrentFilter = [
  set(state`currentFilter`, props`filter`),
  actions.setFilterUrl
]
