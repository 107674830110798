import { init as initSanityClient } from './sanityClient'
import React from 'react'
import { render } from 'react-dom'
import App from 'cerebral'
import Devtools from 'cerebral/devtools'
import { Container } from '@cerebral/react'
import AppComponent from './components/App'
import mainStateModule from './state'
import './index.css'
import * as serviceWorker from './serviceWorker'

initSanityClient()

const app = App(mainStateModule, process.env.NODE_ENV === 'production' ? {} : {
  devtools: Devtools({
    host: 'localhost:8585'
  })
})

render(
  <Container app={app}>
    <AppComponent />
  </Container>,
  document.querySelector('#app')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
