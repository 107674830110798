import React from 'react'
import { connect } from '@cerebral/react'
import { state, signal } from 'cerebral/tags'
import {
  WrapperDesktop,
  WrapperMobile,
  Logo,
  LinksWrapper,
  Link,
  MobileMenuIcon,
  PurpleTriangle,
  DottedLine,
  CenterWrapper,
  LogoLink
} from './elements'
import NavMen1 from './NavMen1'

export default connect(
  {
    mobileMenuIsOpen: state`mobileMenuIsOpen`,
    currentPage: state`currentPage`,
    mobileMenuClicked: signal`mobileMenuClicked`
  },
  class Header extends React.Component {
    render () {
      const { mobileMenuClicked, mobileMenuIsOpen, currentPage } = this.props

      return (
        <div>
          <WrapperDesktop>
            <CenterWrapper>
              <LogoLink href="/">
                <Logo className="image" url={`/logo_menu_wBg.png`} />
              </LogoLink>
              <LinksWrapper>
                <PurpleTriangle />
                <Link active={!currentPage} href="/">
                  HOME
                  {!currentPage ? <DottedLine /> : null}
                </Link>
                <Link active={currentPage === 'news'} href="/news">
                  NEWS
                  {currentPage === 'news' ? <DottedLine /> : null}
                </Link>
                <Link
                  active={currentPage === 'championships'}
                  href="/championships"
                >
                  CHAMPIONSHIPS
                  {currentPage === 'championships' ? <DottedLine /> : null}
                </Link>
                <Link
                  active={currentPage === 'drivers'}
                  href="/drivers"
                >
                  DRIVERS
                  {currentPage === 'drivers' ? <DottedLine /> : null}
                </Link>
                <Link
                  active={currentPage === 'gallery'}
                  href="/gallery"
                >
                  GALLERY
                  {currentPage === 'gallery' ? <DottedLine /> : null}
                </Link>
                <Link
                  active={currentPage === 'partners'}
                  href="/partners"
                >
                  PARTNERS
                  {currentPage === 'partners' ? <DottedLine /> : null}
                </Link>
                <Link
                  active={currentPage === 'contact'}
                  href="/contact"
                >
                  CONTACT
                  {currentPage === 'contact' ? <DottedLine /> : null}
                </Link>
              </LinksWrapper>
            </CenterWrapper>
          </WrapperDesktop>
          <WrapperMobile>
            <MobileMenuIcon
              className="icon-bars"
              onClick={() => mobileMenuClicked()}
            />
            {mobileMenuIsOpen ? <NavMen1 /> : null}
          </WrapperMobile>
        </div>
      )
    }
  }
)
